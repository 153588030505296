"use strict";
/*
 * THE MOST IMPORTANT FUNCTION
 */
function ge(id) {
	return document.getElementById(id);
}

// trim for older browsers from mozilla https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/trim
if (!String.prototype.trim) {
	String.prototype.trim = function () {
		return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
	};
}


if (!Array.prototype.indexOf) {
	Array.prototype.indexOf = function (needle) {
		var i = -1, index = -1;
		for (i = 0; i < this.length; i++) {
			if (this[i] === needle) {
				index = i;
				break;
			}
		}
		return index;
	};
}


if (typeof (Storage) !== "undefined") {
// Code for localStorage/sessionStorage.
} else {
// Sorry! No Web Storage support..
}


/*
 * Class for ajax callback
 * (ajax(args{}, callback function));
 * see possible args from the first switch statement
 * 
 */
function ajax(args, callback, callback_failure) {
	var type = "POST";
	var url = _REST_URI;
	var params = "";
	var data = "json";
	var files = false;
	var fd;
	var xhr = new XMLHttpRequest();
	for (var key in args) {
// possible arguments
		switch (key) {
			case 'type':
				if (args[key].toLowerCase() === "get") {
					type = "GET";
				}
				break;
				// target url
			case 'url':
				url = encodeURI(args['url']);
				break;
				//either key:value object array or POST string key=val&key2=val2...
			case 'params':
				if (typeof args['params'] === 'string' && args['params'].length) {
					if (args['params'].charAt(0) === "?") {
						args['params'] = args['params'].substr(1);
					}
					params = encodeURI(args['params']);
				} else if (typeof args['params'] === 'object') {
					var param_arr = [];
					for (var arg_key in args['params']) {
						if (typeof args['params'][arg_key] === 'object') {
							for (var key_2 in args['params'][arg_key]) {
								param_arr.push(encodeURIComponent(arg_key + '[' + key_2 + ']') + "=" + encodeURIComponent(args['params'][arg_key][key_2]));
							}
							if (args['params'][arg_key].length == 0) {
								param_arr.push(encodeURIComponent(arg_key) + "=");
							}
						} else {
							param_arr.push(encodeURIComponent(arg_key) + "=" + encodeURIComponent(args['params'][arg_key]));
						}
					}

					if (param_arr.length) {
						params = param_arr.join("&");
					}


				}

				break;
			case 'files':

				if (typeof args['files'] === 'object') {
					files = true;
					fd = new FormData();
					for (var arg_key in args['files']) {

						fd.append(arg_key, args['files'][arg_key]);
					}
				}
				break;
			case 'progress_function':
				if (typeof args['progress_function'] === 'function') {
					xhr.upload.addEventListener("progress", args['progress_function']);
				}

				break;
		}
	}

	if (files) {

//this.xhr.addEventListener("load", function(d) {console.log(d);}); 
		xhr.addEventListener("error", function (e) {
			console.log(e);
		});
		xhr.addEventListener("abort", function (e) {
			console.log(e);
		});
		var param_split = params.split("&");
		for (var i = 0; i < param_split.length; ++i) {
			var param_single = param_split[i].split("=");
			fd.append(decodeURIComponent(param_single[0]), decodeURIComponent(param_single[1]));
		}

	}


	if (type === "GET") {
		url += '?' + params;
	}
	xhr.open(type, url, true);
	xhr.onreadystatechange = function () {
		var requestedData;
		if (this.readyState === 4) {
			hide_loading_div();
			if (this.status >= 200 && this.status < 300) {

				try {
					requestedData = JSON.parse(this.responseText);
				} catch (e) {
					console.error(url + ": " + this.responseText);
					throw(e);
				}

				if (args.hasOwnProperty("external") && args.external === true) {
					// external API, let's pass the data through
					callback(requestedData);
					return;
				}

				// log status messages
				if (typeof requestedData.status_messages != 'undefined' && requestedData.status_messages.length) {
					var mes = requestedData.status_messages;
					var messages = [];
					for (var i = 0; i < mes.length; ++i) {
						if (mes[i].console) {
							console.log(mes[i].message);
						} else {
							messages.push(mes[i].message);
						}
					}
					if (messages.length) {

						displayMessages(_('Notice'), messages);
					}

				}

				// Handle user data of the response, is this really smart here???
				if (typeof requestedData.user === 'object' && requestedData.user.status_id) {
					ge("user_level").innerHTML = requestedData.user.status;
					if (requestedData.user.status_id == 3) {
						ge("account_admin_link").style.display = 'block';
					} else {
						ge("account_admin_link").style.display = 'none';
					}
					
					ge("logout_name").innerHTML = requestedData.user.name;
				 	toggleClass(ge("login_menu"), 'hidden', 'on');
					toggleClass(ge("account_div"), 'hidden', 'off');
				} else {
					
					
					toggleClass(ge("login_menu"), 'hidden', 'off');
					ge("logout_name").innerHTML = '';
					ge("user_level").innerHTML = _('Customer');
					toggleClass(ge("account_div"), 'hidden', 'on'); 
				}

				var resp = null;
				if (typeof (requestedData.response) !== "undefined") {
					resp = requestedData.response;
				}


				if (requestedData.status === 'ok') {
					callback(resp);
				} else {
					if (typeof callback_failure !== 'undefined') {
						callback_failure(requestedData);
					}
				}
			} else {
// why is this here?				displayMessages(_('Error'), [_("Unfortunately the server responded with the following error code: %s. Please try again ", this.status)]);
				if (typeof callback_failure !== 'undefined') {
					callback_failure(requestedData, this.status);
				}
			}
		}
	};
	show_loading_div();
	if (type === "POST") {

		if (files) {

			xhr.send(fd);
		} else {
			xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
			xhr.send(params);
		}
	} else {
		xhr.send();
	}
}




function addCSSBrowserPrefix(elem, css_rule, val) {
	elem.style[css_rule] = val;
	var first_letter = css_rule.charAt(0).toUpperCase;
	css_rule = first_letter + css_rule.substr(1);
	elem.style['webkit' + css_rule] = val;
	elem.style['moz' + css_rule] = val;
	elem.style['ms' + css_rule] = val;
	elem.style['o' + css_rule] = val;
}

// Animate all children to enter or exit with css animations 
// This basically just adds animation class to all element type children of the container
// container is the container whose children need to be animated
// see first for state for possible attributes
// TODO:recursive animation to children at different or multiple levels
function animateChildren(container, attr) {

	var duration = 2;
	var delay = 'default';
	var animation = 'va_from_right';
	var cont = false;
	var reverse = false;
	var total_duration = false;
	for (var key in attr) {
		switch (key) {
			case 'duration': // how long one element animation should last in seconds
				duration = parseFloat(attr['duration']);
				break;
			case 'delay': // delay between elements in seconds, if default then delay is the same as animation duration per item  
				if (attr['delay'] === 'default') {
					delay = attr['delay'];
				} else {
					delay = parseFloat(attr['delay']);
				}
				break;
			case 'animation': // class name for the animation from css
				animation = attr['animation'];
				break;
			case 'reverse': // reverse order from actual order TODO: add different ways to order element animations, distance from window sides for circular enterance, element size, random etc.  
				if (attr['reverse']) {
					reverse = true;
				}

				break;
			case 'total_duration': // Total duration of animation in seconds, this overrides duration
				if (attr['total_duration'])
					total_duration = true;
				break;
			case 'container': // animate only container(??!?! Why would someone want to animate just the container with this function, why did I add this ?!?!)
				if (attr['container']) {
					cont = true;
				}
				break;
		}
	}
// collect only actual elements
	var nodes = [];
	if (!cont) {

		for (var i = 0; i < container.childNodes.length; ++i) { // go through child nodes
// TODO:collect nodes with functions to add different order possibilities
			var t = container.childNodes[i];
			if (t.nodeType === 1) { // these are what we want! juicy elements
				nodes.push(t);
			}

		}
	} else {
		nodes.push(container); // for the morons who can't add/replace classes to elements 
	}
// nothing to animate
	if (!nodes.length) {
		return false;
	}

//reverse order if need to
	if (reverse) {
		var new_nodes = [];
		for (var i = nodes.length - 1; i >= 0; --i) {
			new_nodes.push(nodes[i]);
		}
		nodes = new_nodes;
	}


	if (duration < 0) {
		duration = 0;
	}
	var duration_per_object;
	if (total_duration) {
		if (delay !== 'default') {
			duration_per_object = (duration / nodes.length) - (delay * nodes.length);
			if (duration_per_object < 0) {
				duration_per_object = 0;
			}
		} else {
			duration_per_object = duration / nodes.length;
		}




	} else {
		duration_per_object = duration;
	}


	var delay_per_object;
	if (delay === 'default') {
		if (nodes.length > 0) {
			delay_per_object = duration_per_object; // duration/(nodes.length*2); // this doesn't wait until animation stops
		}
	} else {
		delay_per_object = delay;
	}

	for (var i = 0; i < nodes.length; ++i) {
		var t = nodes[i];
		addCSSBrowserPrefix(t, 'animationDuration', duration_per_object + 's');
		addCSSBrowserPrefix(t, 'animationDelay', delay_per_object * i + 's');
		var classnames = t.className.split(' '); // remove old animations from class names
		for (var j = 0; j < classnames.length; ++j) {
			classnames[j] = classnames[j].trim();
			if (classnames[j].indexOf('va_') === 0) {
				classnames.splice(j--, 1);
			}
		}

		if (classnames.length) {
			t.className = classnames.join(' ') + ' ' + animation;
		} else {
			t.className = animation;
		}


	}

}

function getErrorCont(targ_1) {
	var rounds = 10;
	var targ = targ_1.nextSibling;
	while (rounds > 0) {
		if (typeof targ === "undefined" || !targ) {
			rounds = 0;
			break;
		}
		if (targ.nodeType === 1) {

			if (typeof targ.className !== "undefined") {

				if (targ.className.search('error_mes') !== -1) {
					break;
				}
			}
		}

		targ = targ.nextSibling;
		--rounds;
	}


	if (rounds === 0) {
		var error_span = document.createElement('span');
		error_span.className = 'error_mes';
		targ_1.parentNode.insertBefore(error_span, targ_1.nextSibling);
		return error_span;
	} else {
		return targ;
	}
}

function showErrorMessage(targ, mes, ok) {
	toggleClass(targ, 'input_error', 'on');
	var cont = getErrorCont(targ);
	if (cont) {
		if (typeof ok !== 'undefined' && ok) {
			toggleClass(cont, 'error_ok', 'on');
		}
		cont.innerHTML += mes + "<br>";
		cont.style.left = "10px";
		cont.style.opacity = 1;
	}

}

function clearErrorMessages(targ) {
	toggleClass(targ, 'input_error', 'off');
	toggleClass(targ, 'input_ok', 'off');
	var cont = getErrorCont(targ);
	if (cont) {
		toggleClass(cont, 'error_ok', 'off');
		cont.innerHTML = '';
		cont.style.left = "-20px";
		cont.style.opacity = 0;
	}

}

/*
 * toggleClass
 * targ = container
 * className = class name to toggle
 * action = 'on', 'off' or 'switch
 */
function toggleClass(targ, className, action) {
	var classes = targ.className.split(" ");
	var key = classes.indexOf(className);
	if (typeof action === 'undefined') {
		action = "switch";
	}
	if (key === -1) {
		if (action === 'on' || action === 'switch') {
			classes.push(className);
		}

	} else {
		if (action === 'off' || action === 'switch') {
			classes.splice(key, 1);
		}

	}


	targ.className = classes.join(" ");
}

function hasClass(targ, cName) {
	var classes = targ.className.split(" ");
	for (var i = 0; i < classes.length; ++i) {
		if (classes[i].trim() === cName)
			return true;
	}
	return false;
}




function getCaret(elem) {
	return elem.selectionStart;
}

function valueSaved(elem) {
	var div = document.createElement("div");
	div.className = "fast_message_saved";
	div.innerHTML = _("Saved");
	elem.parentNode.insertBefore(div, elem.nextSibling);
	addMes(div, 3, '-');
}

var fast_messages = [];
var fast_message_interval = null;
function showFastMessage(mes, targ) {
	var div = document.createElement("div");
	div.className = "fast_message";
	div.appendChild(document.createTextNode(mes));
	targ.appendChild(div);
	addMes(div, 3, '-');
}

function addMes(e, t, text) {

	fast_messages.push({elem: e, time: t, idText: text});
	if (fast_message_interval === null) {
		fast_message_interval = setInterval("removeFastMessage()", 1000);
	}

}



function removeFastMessage() {

	for (var i = 0; i < fast_messages.length; ++i) {
		if (fast_messages[i].time === 0) {
			var el = fast_messages[i].elem;
			el.parentNode.removeChild(el);
			fast_messages.splice(i, 1);
			--i;
			continue;
		} else {
			--fast_messages[i].time;
		}


	}
	messageTimerRemoval();
}
function messageTimerRemoval() {
	if (fast_messages.length === 0) {
		clearInterval(fast_message_interval);
		fast_message_interval = null;
	}
}



function editField(field) {
	field.disabled = false;
	field.focus();
}

function textareaAutosize(textarea, nocols) {
	if (typeof nocols !== 'boolean') {
		nocols = true;
	}

//console.log(textarea.scrollHeight);
//var text = textarea.value;
//var lines = text.split("\n");
	if (!nocols) {
		/*var longest = 0;
		 for(var i = 0;i<lines.length;++i) {
		 if(lines[i].length > longest) {
		 longest = lines[i].length; 	
		 }
		 }*/
//textarea.cols = longest ? longest : 10;
		textarea.style.width = textarea.scrollWidth + 50 + "px";
	}
//textarea.rows = lines.length ? lines.length : 1;
	if (textarea.scrollHeight === 0) {
		textarea.style.height = 50 + "px";
	} else {
		textarea.style.height = textarea.scrollHeight + "px";
	}


}

function resizeTextareas(nocols) {
	if (typeof nocols !== 'boolean') {
		nocols = true;
	}
	var ta = document.getElementsByTagName('textarea');
	for (var i = 0; i < ta.length; ++i) {
		textareaAutosize(ta[i], nocols);
	}
}

function logErrors(err_arr) {
	for (var i = 0; i < err_arr.length; ++i) {
		console.warn(err_arr[i]);
	}
}

function showErrors(err_arr, targ) {
	clearErrorMessages(targ);
	for (var i = 0; i < err_arr.length; ++i) {
		showErrorMessage(targ, err_arr[i]);
	}
}

function checkEnter(e) {
	var key = e.which || e.keyCode;
	if (key === 13) {
		return true;
	}

	return false;
}

function checkEmail(email) {

	if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email)) {
		return true;
	}
	return false;
}


function displayMessages(title, messages) {
	var mes = '';
	for (var i = 0; i < messages.length; ++i) {
		mes += "<div class=\"prompt_messages\">" + messages[i] + "</div>\n";
	}
	ValotaPrompt.alert(title, mes, null, true);
}

function formatPrice(price, round) {
	if (typeof round !== 'undefined') {
		round = round ? true : false;
	} else {
		round = true;
	}

	
	if (round) {
		var sentit;
		price = Math.round(price / 100);
		var sent = price % 100;
		if (sent > 9) {
			sentit = '.' + sent;
		} else if (sent > 0) {
			sentit = '.0' + sent;
		} else {
			sentit = '.00';
		}



		return parseInt(price / 100) + sentit + ' €';
	} else {
		
		return price / 10000  + ' €';
		
	}
}



function switchMenu(a) {
	if (hasClass(a, "on")) {
		closeMenu(a);
	} else {
		openMenu(a);
	}

}

function openMenu(a) {

	if (!hasClass(a, "on")) {
		a.style.height = a.scrollHeight + "px";
		toggleClass(a, 'on', 'on');
	}

}

function closeMenu(a) {


	if (hasClass(a, "on")) {
		a.style.height = "0px";
		toggleClass(a, 'on', 'off');
	}
}

function isset(test) {
	if (typeof (test) === 'undefined')
		return false;
	return true;
}

function isEmpty(test) {
	if (typeof (test) === 'undefined' || typeof (test) === null || !test)
		return true;
	return false;
}

function goFullscreen(elem) {
	if (elem.requestFullscreen) {
		elem.requestFullscreen();
		return true;
	} else if (elem.webkitRequestFullscreen) {
		elem.webkitRequestFullscreen();
		return true;
	} else if (elem.mozRequestFullScreen) {
		elem.mozRequestFullScreen();
		return true;
	} else if (elem.msRequestFullscreen) {
		elem.msRequestFullscreen();
		return true;
	}
	return false;
}

function switchHiddenMenu(targ) {
	var viz = targ.getAttribute('data-visible');
	var left = true;
	if (targ.className.indexOf('right') !== -1) {
		left = false;
	}

	if (viz == "0") {
		targ.setAttribute('data-visible', 1);
		showHiddenMenu(targ, left);
	} else {
		targ.setAttribute('data-visible', 0);
		hideHiddenMenu(targ, left);
	}


}

function showHiddenMenu(targ, left) {
	targ.style.height = targ.scrollHeight + 4 + "px";
	targ.style.padding = "1px 2px 2px"; // shitty fix for drop shadow
	if (left) {
		targ.style.right = "-2px";
	} else {
		targ.style.left = "-2px";
	}

	targ.style.opacity = 1;
}
function hideHiddenMenu(targ, left) {
	targ.style.height = "0px";
	targ.style.padding = "0"; // shitty fix for drop shadow
	if (left) {
		targ.style.right = "0";
	} else {
		targ.style.left = "0";
	}
	targ.style.opacity = 0;
}

function switchChildContextMenu(targ) {
	var show = false;
	var viz = targ.getAttribute('data-visible');
	if (viz == "0") {
		targ.setAttribute('data-visible', 1);
		show = true;
	} else {
		targ.setAttribute('data-visible', 0);
	}


	var list = targ.childNodes;
	for (var i = 0; i < list.length; ++i) {
		if (isset(list[i].className) && list[i].className.indexOf('context_menu') != -1) {
			var left = true;
			if (list[i].className.indexOf('right') !== -1) {
				left = false;
			}

			if (show) {
				return showHiddenMenu(list[i], left);
			} else {
				return hideHiddenMenu(list[i], left);
			}
		}
	}
	return false;
}

function getParentAttribute(elem, attr) {
	while (elem) {
		if (elem.hasAttribute(attr)) {
			return elem.getAttribute(attr);
		}
		elem = elem.parentNode;
	}
	return false;
}

function tabbedOpen(elem, name) {

// first choose the correct menu item
	var menu_cont = elem.parentNode.children;
	for (var i = 0; i < menu_cont.length; ++i) {
		if (menu_cont[i].nodeName.toLowerCase() == 'li') {
			toggleClass(menu_cont[i], 'chosen', 'off');
		}
	}
	toggleClass(elem, 'chosen', 'on');
	var tab_cont = elem.parentNode.nextSibling;
	var no_loops = 0;
	while (tab_cont.nodeType != 1 || (tab_cont.nodeType == 1 && !tab_cont.getAttribute('data-tab-container'))) {
		tab_cont = tab_cont.nextSibling;
		if (!tab_cont || ++no_loops > 20)
			return false;
	}

	var tabs = tab_cont.firstElementChild.children;
	var found = -1;
	var nummer = -1;
	for (var i = 0; i < tabs.length; ++i) {
		var tab_name = tabs[i].getAttribute('data-tab-name');
		if (!tab_name) {
			continue;
		} else {
			++nummer;
		}
		if (tab_name == name) {
			found = nummer;
		}
	}
	if (found != -1) {
		tab_cont.firstElementChild.style.left = -1 * found * 100 + '%';
		P.changeGet(name);
	}

}

function showSideMenu() {
	toggleClass(ge("main_menu"), 'show', 'on');
	window.setTimeout(function () {
		document.addEventListener("click", hideSideMenu);
	}, 100);
}

function hideSideMenu() {

	toggleClass(ge("main_menu"), 'show', 'off');
	document.removeEventListener("click", hideSideMenu);
}

function toggleToggle(elem, state) {
	var texts = elem.getElementsByTagName('span');
	var ball = elem.getElementsByTagName('div');
	if (typeof state === 'undefined') {
		state = 1 - getToggle(elem);
	}
	if (state) {
		toggleClass(texts[0], 'chosen', 'off');
		toggleClass(texts[1], 'chosen', 'on');
		ball[1].style.left = '1.5em';
	} else {
		toggleClass(texts[1], 'chosen', 'off');
		toggleClass(texts[0], 'chosen', 'on');
		ball[1].style.left = '0';
	}

	return state;
}
function getToggle(elem) {
	var texts = elem.getElementsByTagName('span');
	if (hasClass(texts[0], 'chosen')) {
		return 0;
	}
	return 1;
}

function switchSwitch(el) {
	el.setAttribute("data-active", 1 - parseInt(el.getAttribute("data-active")));
}

function toggleCheckbox(caller, div_to_toggle) {
	var turn = "on";
	if (caller.getAttribute('data-toggle') == 'on') {
		turn = "off";
	}
	caller.setAttribute('data-toggle', turn);
	if (typeof div_to_toggle === 'string') {
		if (turn == "on") {
			ge(div_to_toggle).style.display = 'block';
		} else {
			ge(div_to_toggle).style.display = 'none';
		}
	}

}

function inputHelpers(ev, previous_id, click_id) {
	var key = ev.keyCode;
	// if input is empty and backspace is pressed
	if (ev.target.value.length == 0 && key == 8) {
//focus to the supplied id
		if (previous_id) {
			ge(previous_id).focus();
		}
	}
// click on enter
	if (key == 13) {
		if (click_id) {
			ge(click_id).click();
		}
	}
}
function noticeDiv(type, mes) {
	return '<div class="notice_div ' + type + '"><div>' + mes + '</div></div>';
}

function revealHidden(el, targ) {
	var show = el.getAttribute('data-hidden');
	if (show == 1) {
		var style = window.getComputedStyle(targ);
		el.setAttribute('data-value', style.getPropertyValue('height'));
		targ.style.height = targ.scrollHeight + 'px';
		show = 0;
		toggleClass(el, 'red', 'off');
		toggleClass(el, 'fa-square-o', 'off');
		toggleClass(el, 'fa-check-square-o', 'on');
	} else {
		targ.style.height = el.getAttribute('data-value');
		show = 1;
		toggleClass(el, 'red', 'on');
		toggleClass(el, 'fa-square-o', 'on');
		toggleClass(el, 'fa-check-square-o', 'off');
	}

	el.setAttribute('data-hidden', show);
}

function uploadFileDragHover(e) {
	e.stopPropagation();
	e.preventDefault();
	toggleClass(e.target, "drop", (e.type == "dragover" ? "on" : "off"));
}



function ValotaDDUploadFile(el, drop_function) {
	if (!el || !drop_function || typeof drop_function !== "function") {
		console.warn("Invalid arguments to ValotaDDUploadFile");
		return false;
	}

	this.dragElement = el;
	this.dragElement.handler = this;
	this.dropFunction = drop_function;
	this.dragOver = this.dragLeave = null;
	this.fileTypes = [];
	this.setDragOver = function (func) {
		if (this.dragOver) {
			this.dragElement.removeEventListener("dragover", this.dragOver);
		}
		this.dragElement.addEventListener("dragover", func, false);
		this.dragOver = func;
		return this;
	};
	this.setDragLeave = function (func) {
		if (this.dragLeave) {
			this.dragElement.removeEventListener("dragleave", this.dragLeave);
		}
		this.dragElement.addEventListener("dragleave", func, false);
		this.dragLeave = func;
		return this;
	};
	this.callReturn = function (e) {
		// cancel event and hover styling
		uploadFileDragHover(e);
		// fetch FileList object
		var files = e.target.files || e.dataTransfer.files;
		// process all File objects
		if (!files.length) {
			return false;
		}

		var type = files[0].type;
		var allowed = ['image/gif', 'image/jpeg', 'image/png'];
		if (allowed.indexOf(type) == -1) {
			// invalid data type
			return false;
		}

		this.dragElement.handler.dropFunction(files);
	};
	this.dragElement.addEventListener("drop", this.callReturn, false);
	this.setDragOver(uploadFileDragHover);
	this.setDragLeave(uploadFileDragHover);
}

function hideOverlays() {
	EditCustomApp.returnFullscreen();
	CreateCustomApp.returnFullscreen();
	if (!ValotaPrompt.persistent) {
		ValotaPrompt.hide();
	}
	if (!ValotaPopUp.persistent) {
		ValotaPopUp.hide();
	}
}