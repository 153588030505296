function updateIndex(noChangeListener) {

        if (typeof noChangeListener === 'undefined' || !noChangeListener) {
                document.getElementById("file-select").addEventListener('change', uploadData);
        }
        document.getElementById('approve').addEventListener("click", function (e) {
                var request = new XMLHttpRequest();

                request.onreadystatechange = function () {
                        if (this.readyState === 4) {
                                if (this.status >= 400) {
                                        ValotaPrompt.alert(_('Unable to approve'), '', function () {
                                                console.warn("nok");
                                        });
                                } else {
                                        // success
                                        ValotaPrompt.alert(_('File upload successful'), '', function () {
                                                console.log("ok");
                                        });
                                }
                        }
                };
                request.open("POST", valotaUpload.config.URL);
                if (typeof valotaUpload.config.secret !== "undefined") {
                        request.setRequestHeader("X-Secret", valotaUpload.config.secret);
                }
                var data = new FormData();
                data.append("action", "approve");
                data.append("id", valotaUpload.approveId);

                request.send(data);
        });
        animateChildren(document.getElementById('page_container'), {'animation': 'va_from_down', 'duration': 0.6, 'delay': 0.15});
}

function uploadData(e) {
        document.getElementById('loader').classList.add('active');
        document.getElementById('loader_fg').classList.add('active');
        var form = e.target.parentElement;
        var data = new FormData(form);
        if (valotaUpload.config.hasOwnProperty('postAction')) {
                data.append("action", valotaUpload.config.postAction);
        } else {
                data.append("action", "post");
        }
        if (valotaUpload.config.hasOwnProperty('extraFields')) {
                for (var i = 0; i < valotaUpload.config.extraFields.length; i++) {
                        data.append(valotaUpload.config.extraFields[i], valotaUpload.config.extraFieldValues[i]);
                }
        }
        data.append("file", "file");
        var request = new XMLHttpRequest();
        request.open(form.method, form.action);
        if (typeof valotaUpload.config.secret !== "undefined") {
                request.setRequestHeader("X-Secret", valotaUpload.config.secret);
        }
        request.addEventListener("progress", function () {});
        request.addEventListener("load", function () {
                var json = JSON.parse(request.responseText);
                if (json.hasOwnProperty('status') && json.status === "ok") {
                        var preview = JSON.stringify(json.data.data, null, 2);
                        document.getElementById("result").innerText = preview;
                        document.getElementById("result").className = "success";
                        document.getElementById("data_preview").style.display = "block";
                        document.getElementById('loader').classList.remove('active');
                        document.getElementById('loader_fg').classList.remove('active');
                        document.getElementById('approve').style.display = "";
                        valotaUpload.approveId = json.data.approveId;

                } else {
                        document.getElementById("result").className = "failure";
                        document.getElementById("result").innerHTML = json.message;
                }
        });

        request.addEventListener("readystatechange", function (d) {
                if (this.readyState === 4 && this.status >= 400) {
                        document.getElementById("result").innerText = "Failed to upload";
                        if (request.hasOwnProperty('responseText')) {
                                var json = JSON.parse(request.responseText);
                                if (json.hasOwnProperty('message')) {
                                        document.getElementById("result").innerText = json.message;
                                }
                        }
                        document.getElementById("result").className = "failure";
                        document.getElementById("data_preview").style.display = "block";
                        document.getElementById('loader').classList.remove('active');
                        document.getElementById('loader_fg').classList.remove('active');
                }

        });

        request.addEventListener("error", function () {
                document.getElementById("result").innerText = "Failed to upload";
                if (request.hasOwnProperty('responseText')) {
                        var json = JSON.parse(request.responseText);
                        if (json.hasOwnProperty('message')) {
                                document.getElementById("result").innerText = json.message;
                        }
                }
                document.getElementById("result").className = "failure";
                document.getElementById("data_preview").style.display = "block";
                document.getElementById('loader').classList.remove('animate');
                document.getElementById('loader_fg').classList.remove('active');
        });
        request.addEventListener("abort", function () {
                document.getElementById("result").innerText = "Upload canceled";
                document.getElementById("result").className = "failure";
                document.getElementById("data_preview").style.display = "block";
                document.getElementById('loader').classList.remove('active');
                document.getElementById('loader_fg').classList.remove('active');
        });

        request.send(data);
}

function _() {
        // get the arguments
        var args = [];
        Array.prototype.push.apply(args, arguments);

        //if we have no arguments return an empty string
        if (args.length === 0) {
                return '';
        }

        var text = args[0];

        // replace %s's
        var loc = text.search("%s");
        var num = 1;
        while (loc !== -1) {
                var replacement = '';
                if (typeof args[num] !== 'undefined') {
                        replacement = args[num];
                }

                text = text.replace("%s", replacement);

                loc = text.search("%s");
                ++num;
        }

        return text;
}

function getParams() {
        var t = document.URL.substring(document.URL.indexOf("?") + 1).split("=");
        var retVal = {};
        var name;
        for (var i = 0; i < t.length - 1; i++) {
                name = t[i].split("&");
                if (name.length === 1) // first
                        retVal[name[0]] = t[i + 1].split("&")[0];
                else
                        retVal[name[1]] = t[i + 1].split("&")[0];
        }
        return retVal;
}

function getCustomData(url) {
        var request = new XMLHttpRequest();
        var data = new FormData();
        data.append("action", "custom_data");
        request.open('POST', url);
        if (typeof valotaUpload.config.secret !== "undefined") {
                request.setRequestHeader("X-Secret", valotaUpload.config.secret);
        }
        request.addEventListener("progress", function () {});
        request.addEventListener("load", function () {
                var json = JSON.parse(request.responseText);
//		console.log(json.data.data.elements);

                if (json.hasOwnProperty('status') && json.status === "ok") {
                        $("#file_upload").addClass('custom_data');
                        $("#file_upload").append(json.data.data.elements);
                        $("#file_upload").append('<button type="button" class="big_green" id="upload_button">Send</button>');
                        document.getElementById("upload_button").onclick = uploadData;
                        $("#file_upload").height($("#file_upload").height() * 1.5);

                        updateIndex(true);
                } else {
                        document.getElementById("result").className = "failure";
                        document.getElementById("result").innerHTML = json.message;
                }
        });
        request.send(data);
}

function getConfig() {
        var myParams = getParams();
        $.getJSON(
                valotaUpload.restURL + "?action=config&id=" + myParams.id,
                {},
                function (data) {
//				$(".box.white, .box .padded, .padd_cont, #main_menu").css('background-color',"#"+data.data.bgcolor);
//				$("body").css("color","#"+data.data.color);
                        valotaUpload.config = data.data;
                        $("#file_upload").attr("action", data.data.URL);
                        if (typeof data.data.accept !== "undefined" && data.data.accept !== null) {
                                $("#file-select").attr("accept", data.data.accept);
                        } else {
                                $("#file-select").removeAttr("accept");
                        }
                        $("#upload_name").html(data.data.name + " file upload");
                        if (valotaUpload.config.hasOwnProperty('hasCustomData')) {
                                getCustomData(data.data.URL);
                        } else {
                                updateIndex();
                        }
                },
                function (error, data) {
                        console.error(error, data);
                }
        );

}
getConfig();