/* 
 * VALOTA CONFIDENTIAL
 * __________________
 * 
 * [2013] - [2016] Valota Limited 
 * All Rights Reserved.
 * 
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */


/*
 * ValotaPrompt
 * 
 * report: return function returns object {key:value,key_1:value_1 ...}
 * 
 */

var ValotaPrompt = new function () {
	this.fields = [];
	this.initialized = false;
	this.select2 = true;
	this.persistent = false;
	this.title_text;
	this.info_text;
	this.element;
	this.element_title;
	this.element_info;
	this.element_body;
	this.element_table;
	this.element_ok;
	this.return_func;
	this.return_values;
	this.hide_timeout;
	this.colors;
	this.isConfirm;
	this.visible;
	
	this.init = function() {
		if(!this.initialized) {
			this.element = ge('prompt');
			this.element_title = ge('prompt_head');
			this.element_info = ge('prompt_info');
			this.element_table = ge('prompt_table');
			this.element_ok = ge('prompt_ok');
			this.cover = ge('cover_all');
			this.initialized = true;
			this.visible = false;
		}
	};
	
	this.reset = function () {
		
		ValotaPrompt.init();
		
		this.fields = [];
		this.colors = false;
		this.persistent = false;
		this.element_table.innerHTML = '';
		
		this.element_ok.style.width = '';
		this.element_ok.innerHTML = _('Ok');
		
		ge('prompt_cancel').style.display = '';
		toggleClass(ge('prompt_cancel'), 'cancel', 'on');
		ge('prompt_cancel').innerHTML = _('Cancel');
		this.return_func = null;
		this.return_values = null;
		this.isConfirm = false;
		this.title('Valota Prompt');
		this.info('');

		return this;
	};

	this.alert = function (ti, mes, rf, persistent) {
		this.reset();
		this.title(ti);
		if (isset(mes) && mes) {
			this.info(mes);
		}
		if(typeof(rf) === 'function') {
			this.return_func = rf;
		}
		if(typeof(persistent) !== 'undefined') {
			this.persisent = persistent ? true : false;
		}
		
		this.element_ok.style.width = '100%';
		ge('prompt_cancel').style.display = 'none';
		
		
		this.ask();
	};

	this.confirm = function (ti, mes, func_yes, values) {
		this.reset();
		this.title(ti);
		if (isset(mes) && mes) {
			this.info(mes);
		}
		
		if (isset(values)) {
			this.return_values = values;
		}
		this.isConfirm = true;
		this.return_function(func_yes);

		this.ask();
		
	};

	this.return_function = function (func, values) {
		this.return_func = func;
		if (isset(values)) {
			this.return_values = values;
		}
		return this;
	};
	
	this.persistentPrompt = function () {
		this.persistent = true;
		
		return this;
	};

	this.report = function () {
		this.element_ok.disabled = true;
		this.hide();
		
		if (this.check_values(false, false)) {

			var values = {};
			for (var i = 0; i < this.fields.length; ++i) {
				
				if(this.fields[i].container.tagName.toLowerCase() == 'select' && 
					typeof(this.fields[i].container.multiple) !== 'undefined' &&
					this.fields[i].container.multiple) {
					var elems = this.fields[i].container.selectedOptions;
					
					var val = [];
					
					for(var j = 0;j<elems.length;++j) {
						val.push(elems[j].value);
						
					}
					
					values[this.fields[i].id] = val;
					
					
				} else {

					values[this.fields[i].id] = this.fields[i].container.value;
				}
			}
			if (typeof this.return_func === 'function') {

				if (!isEmpty(this.return_values)) {
					this.return_func(this.return_values, values);
				} else {
					this.return_func(values);
				}

				return true;
			}
			return true;
		}
		return false;
	};
	
	this.check_values = function (report_error, switch_container) {
		switch_cont = true;
		if (typeof (switch_container) !== 'undefined') {
			switch_cont = switch_container;
		}

		var err = false;
		
		for (var i = 0; i < this.fields.length; ++i) {
			var this_err = false;
			clearErrorMessages(this.fields[i].container);
			if (this.fields[i].regex) {
				var reg = new RegExp(this.fields[i].regex);
				
				if(this.fields[i].container.tagName.toLowerCase() == 'select' && 
					typeof(this.fields[i].container.multiple) !== 'undefined' &&
					this.fields[i].container.multiple) {
					var elems = this.fields[i].container.selectedOptions;
					console.log(elems);
					for(var j = 0;j<elems.length;++j) {
						if (!reg.test(elems[j].value)) {
							
					
							this_err = true;
						}
					}
					
					
				} else {
				
					if (!reg.test(this.fields[i].container.value)) {
					
						this_err = true;
					}
				}
			}
			if (!this.fields[i].container.checkValidity()) {
				
				this_err = true;

			}
			if (this_err ) {
				err = true;
				if( report_error) {
					showErrorMessage(this.fields[i].container, "Not a valid value");
				}
			}
			
		}
		if (switch_cont) {
			if (err) {
				this.element_ok.disabled = true;
			} else {
				this.element_ok.disabled = false;
			}
		}
		return !err;

	};
	this.ok_text = function(text) {
		this.element_ok.innerHTML = text;
		return this;
	};

	this.field = function (id, type, args) {
		var dom_id = "ValotaPrompt_" + id;
		var tr = document.createElement('tr');
		var td = document.createElement('td');

		if (typeof (args['title']) !== 'undefined') {
			var label = document.createElement('label');
			label.setAttribute('for', dom_id);
			var text = args['title'];
			
			if (typeof (args['required']) !== 'undefined' && args['required']) {
				text += '<span class="required">*</span>';
			}
			if (typeof (args['info']) !== 'undefined') {
				text += '<div class="info">' + args['info'] +'</div>';
			}

			label.innerHTML = text;

			td.appendChild(label);
			tr.appendChild(td);
		} else {
			td.colspan = "2";
		}


		td = document.createElement('td');

		if (type === "text" || type === "number" || type === "date" || type === "email" || type === "color" || type === "url" || type === "file" || type === "textarea") {

			if (type === 'textarea') {
				var input = document.createElement('textarea');
			} else {
				var input = document.createElement('input');
				input.onkeyup = function(e) {
					if (e.keyCode == 13) {
						ValotaPrompt.element_ok.click();
					}
				}
				input.className="tepid";
				input.type = (type == "color" ? "text" : type);
			}

			input.id = dom_id;

			if (type == "color") {
				this.colors = true;
				input.className = 'color';
			}
			
			if (type == "number") {
				if (typeof (args['min']) !== 'undefined') {
					input.min = args['min'];
				}
				if (typeof (args['max']) !== 'undefined') {
					input.max = args['max'];
				}
				if (typeof (args['step']) !== 'undefined') {
					input.step = args['step'];
				}
			}

			if (typeof (args['type']) !== 'undefined') {
				input.type = args['type'];
			}
			if (typeof (args['placeholder']) !== 'undefined') {
				input.placeholder = args['placeholder'];
			}
			if (typeof (args['value']) !== 'undefined') {
				if (type === 'textarea') {
					input.innerHTML = args['value'];
				} else {
					input.value = args['value'];
				}
			}

			if (typeof (args['required']) !== 'undefined') {
				input.required = args['required'];
			}

			input.oninput = function () {
				ValotaPrompt.check_values(true);
			};
			td.appendChild(input);
			var error_div = document.createElement('div');
			error_div.className = 'error_mes under';
			td.appendChild(error_div);

			var regex = false;
			if (typeof (args['regex']) !== 'undefined') {
				regex = args['regex'];
			}

			this.fields.push({
				container: input,
				id: id,
				regex: regex
			});





		}

		if (type === "select") {

			var select = document.createElement('select');
			select.className = 'tepid';
			select.id = dom_id;



			if (typeof (args['options']) !== 'undefined') {
				var selected = false;
				if (typeof (args['selected']) !== 'undefined') {
					selected = args['selected'];
				}

				for (var key in args['options']) {
					var option = document.createElement('option');
					option.value = key;
					option.innerHTML = args['options'][key];
					if (selected && selected === key) {
						option.selected = true;
					}
					select.appendChild(option);
				}
			}

			if (typeof (args['required']) !== 'undefined') {
				select.required = args['required'];
			}
			
			if (typeof (args['multiple']) !== 'undefined' && args['multiple']) {
				
				select.multiple = true;
			}

			select.onchange = function () {
				ValotaPrompt.check_values(true);
			};
			
		
			
			td.appendChild(select);
			
			if(this.select2) {
				$(select).select2();
			}
			
			var error_div = document.createElement('div');
			error_div.className = 'error_mes under';
			td.appendChild(error_div);

			var regex = false;
			if (typeof (args['regex']) !== 'undefined') {
				regex = args['regex'];
			}

			this.fields.push({
				container: select,
				id: id,
				regex: regex
			});

		}
		

		tr.appendChild(td);

		this.element_table.appendChild(tr);

		return this;
	};

	this.title = function (title) {
		this.title_text = title;
		this.element_title.innerHTML = this.title_text;

		return this;
	};

	this.info = function (info) {
		this.info_text = info;
		this.element_info.innerHTML = this.info_text;

		return this;
	};


	this.ask = function () {
		if (this.colors) {
			jsColorPicker('input.color', {
				customBG: '#222',
				readOnly: true,
				appendTo: document.getElementById('prompt'),
				// patch: false,
				init: function (elm, colors) { // colors is a different instance (not connected to colorPicker)
					elm.style.backgroundColor = elm.value;
					elm.style.color = colors.rgbaMixCustom.luminance > 0.22 ? '#222' : '#ddd';
				}
			});
		}
		

		if (ValotaPrompt.hide_timeout) {

			clearTimeout(ValotaPrompt.hide_timeout);
		}
		ValotaPrompt.check_values(false);
		ValotaPrompt.show();


	};

	this.show = function () {
		ValotaPrompt.init();
		this.visible = true;
		document.body.style.overflow="hidden";
		ge('cover_all').style.display = 'block';
		if(this.fields.length) {
			if(this.fields[0].container.className != 'color') {
				this.fields[0].container.focus();
			}
		}
		window.setTimeout(ValotaPrompt.do_show, 50);

	};
	this.do_show = function () {
		addCSSBrowserPrefix(ValotaPrompt.element, 'transform', 'translateY(0px)');
		ValotaPrompt.element.style.opacity = "1";
		ValotaPrompt.cover.style.backgroundColor = "rgba(255,255,255,.95)";

	};

	this.hide = function () {
		ValotaPrompt.init();
		this.visible = false;
		document.body.style.overflow="auto";
		ValotaPrompt.prepare_hide();
		ValotaPrompt.hide_timeout = window.setTimeout(ValotaPrompt.do_hide, 500);
	};

	this.prepare_hide = function () {
		addCSSBrowserPrefix(ValotaPrompt.element, 'transform', 'translateY(-20px)');
		ValotaPrompt.element.style.opacity = "0";
		ValotaPrompt.cover.style.backgroundColor = "rgba(255,255,255,0)";
	};

	this.do_hide = function () {

		ValotaPrompt.hide_timeout = null;
		ValotaPrompt.cover.style.display = 'none';
	};

};